<template>
  <div class="lottery-draw">
    <el-card>
      <!-- 顶部工具栏 -->
      <div class="toolbar">
        <el-button 
          type="primary" 
          icon="el-icon-refresh"
          :loading="loading"
          @click="handleRefresh"
        >
          刷新
        </el-button>
      </div>

      <!-- PC端表格 -->
      <el-table 
        v-if="!isMobile"
        :data="drawList"
        v-loading="loading"
        style="width: 100%; margin-top: 15px;"
      >
        <el-table-column label="彩种" width="140">
          <template slot-scope="scope">
            {{ scope.row.lottery?.name }}
          </template>
        </el-table-column>
        <el-table-column prop="drawNo" label="当期期号" width="120" />
        <el-table-column prop="drawNumbers" label="开奖号码" min-width="120" />
        <el-table-column prop="drawStatus" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.drawStatus)">
              {{ getStatusText(scope.row.drawStatus) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="nextDrawNo" label="下期期号" width="120" />
        <el-table-column prop="specifiedNumbers" label="预开号码" width="160">
          <template slot-scope="scope">
            {{ scope.row.specifiedNumbers || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="nextDrawTime" label="下期时间" width="160">
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.nextDrawTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button 
              type="text"
              @click="handleEdit(scope.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端列表 -->
      <div v-else class="mobile-list">
        <el-card 
          v-for="item in drawList" 
          :key="item.id" 
          class="mobile-item"
          shadow="hover"
        >
          <div class="mobile-item-header">
            <div class="lottery-info">
              <span class="lottery-name">{{ item.lottery?.name }}</span>
              <span class="draw-no">第 {{ item.drawNo }} 期</span>
            </div>
            <el-tag :type="getStatusType(item.drawStatus)" size="small">
              {{ getStatusText(item.drawStatus) }}
            </el-tag>
          </div>
          
          <div class="mobile-item-body">
            <div class="info-row">
              <span class="label">开奖号码：</span>
              <span class="value">{{ item.drawNumbers || '待开奖' }}</span>
            </div>
            <div class="info-row">
              <span class="label">下期期号：</span>
              <span class="value">{{ item.nextDrawNo }}</span>
            </div>
            <div class="info-row">
              <span class="label">预开号码：</span>
              <span class="value">{{ item.specifiedNumbers || '-' }}</span>
            </div>
            <div class="info-row">
              <span class="label">下期时间：</span>
              <span class="value">{{ formatDateTime(item.nextDrawTime) }}</span>
            </div>
          </div>

          <div class="mobile-item-footer">
            <el-button 
              type="primary" 
              size="small"
              @click="handleEdit(item)"
            >
              编辑
            </el-button>
          </div>
        </el-card>
      </div>
    </el-card>

    <!-- 编辑对话框 -->
    <el-dialog
      title="编辑开奖信息"
      :visible.sync="editVisible"
      :width="isMobile ? '95%' : '500px'"
      :fullscreen="isMobile"
    >
      <el-form 
        ref="editForm"
        :model="editForm"
        :rules="editRules"
        label-width="100px"
      >
        <el-form-item label="当期期号" prop="drawNo">
          <el-input v-model="editForm.drawNo" placeholder="请输入当期期号" />
        </el-form-item>
        <el-form-item label="开奖号码" prop="drawNumbers">
          <el-input v-model="editForm.drawNumbers" placeholder="请输入开奖号码" />
        </el-form-item>
        <el-form-item label="开奖状态" prop="drawStatus">
          <el-select v-model="editForm.drawStatus" placeholder="请选择状态" style="width: 100%">
            <el-option label="未开奖" :value="0" />
            <el-option label="开奖中" :value="1" />
            <el-option label="已开奖" :value="2" />
            <el-option label="开奖异常" :value="-1" />
          </el-select>
        </el-form-item>
        <el-form-item label="快速开奖" prop="fastDraw">
          <el-switch
            v-model="editForm.fastDraw"
            :active-value="1"
            :inactive-value="0"
            active-text="开启"
            inactive-text="关闭"
          />
        </el-form-item>
        <el-form-item label="亏盈比例" prop="profitRatio">
          <el-input-number 
            v-model="editForm.profitRatio" 
            :min="-100"
            :max="100"
            :step="1"
            style="width: 160px"
          />
          <span class="unit">%</span>
        </el-form-item>
        <el-form-item label="下期时间" prop="nextDrawTime">
          <el-date-picker
            v-model="editForm.nextDrawTime"
            type="datetime"
            placeholder="选择下期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="投注截止" prop="betDeadline">
          <el-date-picker
            v-model="editForm.betDeadline"
            type="datetime"
            placeholder="选择投注截止时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="下期期号" prop="nextDrawNo">
          <el-input v-model="editForm.nextDrawNo" placeholder="请输入下期期号" />
        </el-form-item>
        <el-form-item label="预开号码" prop="specifiedNumbers">
          <div style="display: flex; align-items: center;">
            <el-input 
              v-model="editForm.specifiedNumbers" 
              placeholder="请输入指定开奖号码" 
              style="flex: 1;"
            />
            <el-button 
              type="primary" 
              size="small" 
              style="margin-left: 10px; white-space: nowrap;"
              :disabled="!editForm.specifiedNumbers"
              @click="calculateProfit"
            >
              预算
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit" :loading="submitting">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LotteryDraw',
  data() {
    return {
      loading: false,
      submitting: false,
      drawList: [],
      isMobile: false,
      editVisible: false,
      editForm: {
        id: null,
        lotteryId: null,
        drawNo: '',
        drawNumbers: '',
        specifiedNumbers: null,
        profitRatio: 0,
        drawStatus: 0,
        nextDrawNo: '',
        nextDrawTime: '',
        betDeadline: '',
        fastDraw: 0
      },
      editRules: {
        drawNo: [
          { required: true, message: '请输入当期期号', trigger: 'blur' }
        ],
        nextDrawNo: [
          { required: true, message: '请输入下期期号', trigger: 'blur' }
        ],
        nextDrawTime: [
          { required: true, message: '请选择下期时间', trigger: 'change' }
        ],
        betDeadline: [
          { required: true, message: '请选择投注截止时间', trigger: 'change' }
        ],
        profitRatio: [
          { required: true, message: '请输入亏盈比例', trigger: 'blur' },
          { type: 'number', message: '亏盈比例必须为数字', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchData() {
      this.loading = true
      try {
        const res = await this.$http.get('/api/admin/lottery/current-draws')
        if (res.data.code === 200) {
          this.drawList = res.data.data.sort((a, b) => {
            return (b.lottery?.sort || 0) - (a.lottery?.sort || 0)
          })
        }
      } catch (error) {
        console.error('获取开奖数据失败:', error)
        this.$message.error('获取数据失败')
      } finally {
        this.loading = false
      }
    },
    getStatusType(status) {
      const typeMap = {
        '-1': 'danger',
        '0': 'info',
        '1': 'warning',
        '2': 'success'
      }
      return typeMap[status] || 'info'
    },
    getStatusText(status) {
      const textMap = {
        '-1': '开奖异常',
        '0': '未开奖',
        '1': '开奖中',
        '2': '已开奖'
      }
      return textMap[status] || '未知状态'
    },
    formatDateTime(time) {
      if (!time) return ''
      const date = new Date(time)
      return date.toLocaleString()
    },
    handleEdit(row) {
      this.editForm = {
        id: row.id,
        lotteryId: row.lotteryId,
        drawNo: row.drawNo,
        drawNumbers: row.drawNumbers || '',
        specifiedNumbers: row.specifiedNumbers || null,
        profitRatio: row.profitRatio || 0,
        drawStatus: row.drawStatus,
        nextDrawNo: row.nextDrawNo,
        nextDrawTime: row.nextDrawTime,
        betDeadline: row.betDeadline,
        fastDraw: row.fastDraw || 0
      }
      this.editVisible = true
    },
    async submitEdit() {
      try {
        await this.$refs.editForm.validate()
        this.submitting = true
        const res = await this.$http.put(`/api/admin/lottery/draws/${this.editForm.id}`, this.editForm)
        if (res.data.code === 200) {
          this.$message.success('编辑成功')
          this.editVisible = false
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('编辑失败:', error)
          this.$message.error('编辑失败')
        }
      } finally {
        this.submitting = false
      }
    },
    // 添加刷新方法
    handleRefresh() {
      this.fetchData()
    },
    async calculateProfit() {
      try {
        if (!this.editForm.lotteryId) {
          throw new Error('缺少彩种ID');
        }
        if (!this.editForm.nextDrawNo) {
          throw new Error('缺少期号');
        }
        if (!this.editForm.specifiedNumbers) {
          throw new Error('请先输入预开号码');
        }

        const response = await this.$http.post('/api/admin/lottery/calculate-profit', {
          lotteryId: this.editForm.lotteryId,
          drawNo: this.editForm.nextDrawNo,
          drawNumbers: this.editForm.specifiedNumbers
        });

        if (response.data.code === 200) {
          const data = response.data.data;
          const totalBetAmount = Number(data.totalBetAmount || 0);
          const totalWinAmount = Number(data.totalWinAmount || 0);
          const actualProfitRatio = Number(data.actualProfitRatio || 0);
          const profit = Number(data.profit || 0);
          const message = data.message || '';
          
          this.$message({
            type: 'info',
            dangerouslyUseHTMLString: true,
            message: `
              <div style="text-align: left">
                <div>${message}</div>
                <div>总投注: ${totalBetAmount.toFixed(2)} 元</div>
                <div>总派奖: ${totalWinAmount.toFixed(2)} 元</div>
                <div>盈亏: ${profit.toFixed(2)} 元</div>
                <div>亏盈比例: ${actualProfitRatio.toFixed(2)}%</div>
              </div>
            `
          });
        }
      } catch (error) {
        this.$message.error('计算失败: ' + (error.message || '未知错误'));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-draw {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  // 添加工具栏样式
  .toolbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
      .el-button {
        width: 100%;
      }
    }
  }

  .mobile-list {
    .mobile-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .mobile-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .lottery-info {
          .lottery-name {
            font-size: 16px;
            font-weight: 500;
            margin-right: 10px;
          }

          .draw-no {
            font-size: 14px;
            color: #909399;
          }
        }
      }

      .mobile-item-body {
        .info-row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            width: 80px;
            color: #909399;
            font-size: 14px;
          }

          .value {
            flex: 1;
            font-size: 14px;
          }
        }
      }

      .mobile-item-footer {
        margin-top: 12px;
        display: flex;
        justify-content: center;

        .el-button {
          min-width: 120px;
        }
      }
    }
  }

  .form-tip {
    font-size: 12px;
    color: #909399;
    margin-top: 4px;
  }

  .unit {
    margin-left: 8px;
    color: #606266;
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .lottery-draw {
    .mobile-list {
      .mobile-item {
        .mobile-item-header {
          .lottery-info {
            .lottery-name {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style> 